<template>
  <div class="booksnippet row no-gutters align-items-center justify-content-center">
    <template  v-if="book && book.id">
      <div class="carousel_div col-md-2 pt-2" v-if="book.coverimage">
        <b-carousel :interval="10000" :controls="showCarouselCtrl" v-if="imgCarousel">
          <b-carousel-slide  v-for="(v, i) in book.coverimage" :key="i" :img-src="v" />
        </b-carousel>
        <b-img :src="book.coverimage[0]" :alt="book.title" v-else />
      </div>
      <div class="col-md-10 p-2 p-md-3 text-center text-md-left">
        <h5>
          <b-link :to="toLink" v-if="toLink">{{book.title}}</b-link>
          <span v-else>{{book.title}}</span>
        </h5>
        <div class="text-muted">
          <div>{{book.subtitle}}</div>
          <div class="mt-2" v-if="book.author"><AuthorIcon title="Authors" class="mr-1" />{{ book.author.join(', ') }}</div>
        </div>
        <p class="mt-2">{{book.abstract}}</p>
      </div>
    </template>
    <b-overlay show v-else />
  </div>
</template>

<script>
import AuthorIcon from 'mdi-vue/AccountMultipleOutline';

export default {
  components: { AuthorIcon },
  props: {
    book: {
      type: Object,
      default() { return {}; },
    },
    toLink: { type: Object, default: null },
    imgCarousel: { type: Boolean, default: false },
  },

  computed: {
    showCarouselCtrl() {
      // show the carousel control only when coverimages are more than 1
      return this.book && this.book.coverimage && (this.book.coverimage.length > 1);
    },
  },

};
</script>

<style lang="stylus" scoped>

.booksnippet
  .carousel_div
    position relative
    max-width 6rem
    overflow hidden
    img
      width 100%

</style>
